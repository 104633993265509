import React from "react";
import Context from "./context";
import { getProducts, searchProducts } from "../components/Product/Product";
class contextProvider extends React.Component {
  state = {
    products: [],
    startIndex: 0,
    category: "All",
    activeIndex: 0,
    limit: 8,
    rowCount: 0,
    menuHeight: 0,
  };

  getProducts = () => {
    return new Promise(async (resolve, reject) => {
      let products = await getProducts(
        this.state.category,
        this.state.startIndex
      );
      let rowCount = Math.ceil(products.length / this.state.limit);
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            products,
            rowCount,
            startIndex: products.length,
          };
        },
        () => {
          this.setHeight();
        }
      );
    });
  };

  onChangePage = (page) => {
    this.setState((prevState) => {
      return { ...prevState, activeIndex: page };
    });
  };

  setCategory = (category) => {
    return new Promise(async (resolve, reject) => {
      this.setState(
        { ...this.state, category, startIndex: 0, activeIndex: 0 },
        () => {
          this.getProducts();
          resolve();
        }
      );
    });
  };

  searchProducts = async (searchTerm) => {
    try {
      let products = await searchProducts(this.state.category, searchTerm);
      let rowCount = Math.ceil(products.length / this.state.limit);
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            products,
            startIndex: products.length,
            activeIndex: 0,
            rowCount,
          };
        },
        () => {
          this.setHeight();
        }
      );
    } catch (error) {}
  };

  setHeight = () => {
    if (document.getElementById("row0")) {
      let height = document.getElementById("row0").clientHeight;
      this.setState((prevState) => {
        return { ...prevState, height };
      });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{
          setCategory: this.setCategory,
          getProducts: this.getProducts,
          onChangePage: this.onChangePage,
          searchProducts: this.searchProducts,
          ...this.state,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default contextProvider;
