import React from "react";
import styles from "./shared.module.scss";
import { cssClass } from "../../utils/cssClassCreator";

const Footer = () => {
  return (
    <footer className={cssClass("navbar", styles.footer)}>
      <div className="container px-1">
        <p className={cssClass("mb-0 text-white fw-light", styles.p1)}>
          Copyrights@fabnfitbakes2021
        </p>
      </div>
    </footer>
  );
};

export default Footer;
