import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Footer from "./components/Shared/Footer";
import asyncComponent from "./components/Shared/AsyncComponent";
import ContextProvider from "./utils/gloabalState";

const history = createBrowserHistory();

function App() {
  const Home = asyncComponent(() =>
    import("./components/Home").then((module) => module.default)
  );

  const ProductDescription = asyncComponent(() =>
    import("./components/Product/ProductDescription/ProductDescription").then(
      (module) => module.default
    )
  );

  return (
    <ContextProvider>
      <Router history={history}>
        <Route path="/" render={({ match: url }) => (
          <>
            <Route exact path="/" component={Home} />
            <Route path="/products/:id" component={ProductDescription} />
          </>
        )} />
      </Router>
      <Footer />
    </ContextProvider>
  );
}

export default App;
