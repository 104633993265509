import React from 'react';

export default React.createContext({
    products: [],
    startIndex: 0,
    category: "All",
    activeIndex: 0,
    limit: 8,
    rowCount: 0,
    menuHeight: 0,
    getProducts: async () => { },
    setCategory: (category) => { },
    searchProducts: (searchTerm) => { },
    onChangePage: async (page) => { }
});

