import products from "./product.json";

export const getProducts = (category) => {
  return new Promise((resolve, reject) => {
    if (category === "All") {
      resolve(products);
      return;
    }
    let newArray = products.filter((product) => product.category === category);
    resolve(newArray);
  });
};

export const searchProducts = (category, searchTerm) => {
  return new Promise((resolve, reject) => {
    let newArray = [];
    if (category === "All") {
      newArray = products.filter(
        (product) =>
          product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
      resolve(newArray);
      return;
    }

    newArray = products.filter(
      (product) =>
        product.category === category &&
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    resolve(newArray);
  });
};

export const getProduct = (id) => {
  return new Promise(
    (resolve, reject) => {
      const product = products.find((product) => product.id === +id);
      resolve(product);
    },
    (reject) => { }
  );
};
